import './styles/app.scss';

document.addEventListener("DOMContentLoaded", () => {
  const burger = document.querySelector('.wrapper-burger')
  const menu = document.querySelector('.menu-mob-fix')
  const plus = document.querySelector('.plus')
  const cross = document.querySelector('.cross')
  const menuAllLink = menu.querySelectorAll('a')

  document.body.addEventListener('click', () => {
    menu.classList.remove('menu-mob-fix__active')
    plus.classList.remove('hidden')
    cross.classList.add('hidden')
  })

  menu.addEventListener('click', (e) => {
    e.stopPropagation()
  })

  burger.addEventListener('click', (e) => {
    e.stopPropagation()
    menu.classList.toggle('menu-mob-fix__active')
    plus.classList.toggle('hidden')
    cross.classList.toggle('hidden')
  })

  menuAllLink.forEach(link => {
    link.addEventListener('click', () => {
      menu.classList.toggle('menu-mob-fix__active')
      plus.classList.toggle('hidden')
      cross.classList.toggle('hidden')
    })
  })

});
